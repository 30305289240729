.listtable th {
  padding-left: 4px;
}

.listtable td {
  white-space: nowrap;
  padding-left: 4px;
  padding-right: 4px;
}

.listtable .sageid {
  color: grey;
  font-size: 0.75rem;
}

.listtable tr {
  position: relative;
}
a.expandRowLink:after {
  content: '';
  position:absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: block;
  width: 100%;
  z-index: 1;
  pointer-events: auto;
  background-color: rgba(0,0,0,0);
}

.listtable .joblistname {
  max-width: 300px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.listtable .joblistrestrict {
  max-width: 200px;
  overflow: hidden;
  text-overflow: ellipsis;
}
