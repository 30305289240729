
.bottomDrawerContainer {
  position: fixed;
  bottom: 0;
  width: 100%;
  height: 30;
  z-index: 100;
  transition: "height 1s";
}

.bottomDrawerContainer:hover {
  background-color: rgba(0,0,150,.1);
}

