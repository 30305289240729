/* .jobcosttable {
  font-weight: bolder;
} */

.jobcosttable tr th {
  padding-left: 1px;
}

.jobcosttable .hasOnHover {
  cursor: pointer;
}

.jobcosttable .hasOnHover:hover {
  background-color: rgba(0, 0, 0, 0.08);
  box-shadow: 0px 0px 3px 3px rgba(0, 0, 0, 0.08);
}


.jobcosttable .rightaligninput input {
  text-align: right;
}

.jobcosttable input.rawrightinput {
  border: 0;
  border-bottom: 2px solid grey;
  background: none;
  padding: 4px 0 5px;
  text-align: right;
  font-family: Roboto, Helvetica, Arial, sans-serif;
  font-size: 16px;
  width: 100%;
}

.jobcosttable input.rawrightinput:disabled {
  outline: 0;
  border-bottom: 2px solid lightgrey;
}

.jobcosttable input.rawrightinput.error {
  outline: 0;
  border-bottom: 2px solid red !important;
}

.jobcosttable input.rawrightinput:focus {
  outline: 0;
  border-bottom: 2px solid rgb(120, 120, 255);
}

.jobcosttable .rightaligninput input:invalid {
  color: red
}